import React, { useState } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Form, Input, Button, Select, Checkbox, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Styles from './google-form.module.css';

const GoogleForm = () => {
  const [formSubmitLoading, setFormSubmittedLoading] = useState(false);
  const { Option } = Select;

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
    notification.open({
      message: 'Incomplete Form Entries',
      description:
        'Please complete all application fields and submit again! Thank you.',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
    });
  };

  const onFinish = async (values) => {
    setFormSubmittedLoading(true);
    console.log('Success:', values);
    const {
      'entry.1095116907': name,
      'entry.767079705': email,
      'entry.1168036818': ekklesiaLeader,
      'entry.332739173': orthopraxisFacilitator,
      'entry.1339470398': course,
      'entry.301538359': whyParticipate,
      'entry.1092339119': activeEkklesia,
      'entry.644544156': serving,
      'entry.203202103': schedulingConflicts,
      'entry.7500914': dates,
    } = values;

    const formData = new FormData();
    formData.append('entry.1095116907', name);
    formData.append('entry.767079705', email.toUpperCase());
    formData.append('entry.1168036818', ekklesiaLeader);
    formData.append('entry.332739173', orthopraxisFacilitator);
    formData.append('entry.1339470398', course);
    formData.append('entry.301538359', whyParticipate);
    formData.append('entry.1092339119', activeEkklesia);
    formData.append('entry.644544156', serving);
    formData.append('entry.203202103', schedulingConflicts);

    dates.forEach((date) => {
      console.log(date);
      formData.append('entry.7500914', date);
    });

    await axios({
      url: process.env.GOOGLE_FORM_URL,
      method: 'POST',
      data: formData,
      responseType: 'json',
    });
    await redirectToCheckout(email, course);
    setFormSubmittedLoading(false);
  };

  const redirectToCheckout = async (email, course) => {
    const courseName = course.split(' ')[0];
    const stripePromise = loadStripe(process.env.STRIPE_API_KEY);
    const stripe = await stripePromise;

    // Nested trycatch to retry for initial Stripe 400
    try {
      // call netlify function for purchase
      console.log('first');
      const { data } = await axios({
        url: `${process.env.LAMBDA_ENDPOINT}/purchase?course=${courseName}&email=${email}`,
        method: 'POST',
      });
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });
    } catch (error) {
      try {
        console.log('first');
        const { data } = await axios({
          url: `${process.env.LAMBDA_ENDPOINT}/purchase?course=${courseName}&email=${email}`,
          method: 'POST',
        });
        const result = await stripe.redirectToCheckout({
          sessionId: data.id,
        });
      } catch (error) {
        console.error(error.errorMessage);
        notification.open({
          message: 'Network connection error.',
          description:
            'Please try submitting your application again. Thank you!',
          icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
        });
      }
    }
  };

  return (
    <div className={Styles.form}>
      {/* <div
        style={{
          textAlign: 'center',
          paddingTop: '2rem',
          paddingBottom: '2rem',
          fontWeight: 'bold',
          fontSize: '2.5rem',
        }}
      >
        Sign ups are now closed.
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '2.5rem' }}>
        We will be back with more Advances in 2022.
      </div> */}
      {/* Uncomment the form below and make appropriate changes to the dates, course selection (so that it matches the same casing and options on the actual Google Form) */}
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Name"
          name="entry.1095116907"
          rules={[
            {
              required: true,
              message: 'Name required.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          hasFeedback
          name="entry.767079705"
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid email address.',
            },
            {
              required: true,
              message: 'Email required.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Ekklesia Leader"
          name="entry.1168036818"
          rules={[
            {
              required: true,
              message: 'Ekklesia Leader required.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Orthopraxis Facilitator"
          name="entry.332739173"
          rules={[
            {
              required: true,
              message: 'Orthopraxis Facilitator required.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Please select the Advance you're interested in."
          name="entry.1339470398"
          rules={[
            {
              required: true,
              message: 'Course selection required.',
            },
          ]}
        >
          <Select placeholder="Select Course">
            <Option value="Vocation Advance">Vocation Advance (Online)</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Please explain why you would like to participate in the selected Advance."
          name="entry.301538359"
          rules={[
            {
              required: true,
              message: 'Response required.',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Are you actively committed to your Ekklesia? If not, please explain."
          name="entry.1092339119"
          rules={[
            {
              required: true,
              message: 'Response required.',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Are you serving in some capacity at Ekko?"
          name="entry.644544156"
          rules={[
            {
              required: true,
              message: 'Response required.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Please select which meetings you will be able to attend."
          name="entry.7500914"
          rules={[
            {
              required: true,
              message: 'Dates required.',
            },
          ]}
        >
          <Checkbox.Group
            options={[
              {
                label: 'February 2, 2022 (Wednesday, 7:00—9:00PM)',
                value: 'February 2, 2022 (Wednesday, 7:00—9:00PM)',
              },
              {
                label: 'February 9, 2022 (Wednesday, 7:00—9:00PM)',
                value: 'February 9, 2022 (Wednesday, 7:00—9:00PM)',
              },
              {
                label: 'February 16, 2022 (Wednesday, 7:00—9:00PM)',
                value: 'February 16, 2022 (Wednesday, 7:00—9:00PM)',
              },
              {
                label: 'March 2, 2022 (Wednesday, 7:00—9:00PM)',
                value: 'March 2, 2022 (Wednesday, 7:00—9:00PM)',
              },
              {
                label: 'March 9, 2022 (Wednesday, 7:00—9:00PM)',
                value: 'March 9, 2022 (Wednesday, 7:00—9:00PM)',
              },
              {
                label: 'March 16, 2022 (Wednesday, 7:00—9:00PM)',
                value: 'March 16, 2022 (Wednesday, 7:00—9:00PM)',
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="For any scheduling conflicts, please explain why."
          name="entry.203202103"
          rules={[
            {
              required: true,
              message: 'Response required.',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <p className={Styles.note}>
            *Please complete registration by waiting to be redirected to payment
            page after form submission.
          </p>
          <Button
            className={Styles.btn}
            htmlType="submit"
            loading={formSubmitLoading}
          >
            SUBMIT
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GoogleForm;
