import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import GoogleForm from '../components/form/google-form';
import SignUpHero from '../components/image-components/hero/signUpHero';

const SignUp = () => {
  return (
    <>
      <SEO title="Sign Up" />
      <Layout>
        <SignUpHero title="SIGN UP" />
        <GoogleForm />
      </Layout>
    </>
  );
};

export default SignUp;
